<template>
  <div v-loading="loader">
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1>Add Resource</h1>
      <bari-resource-form
        @storeBariResource="storeBariResource"
      />
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import BariResourceForm from "@/views/Pages/SuperAdmin/BariResource/BariResourceForm";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "StoreBariResource",
  components: {BariResourceForm, BackButton},
  data() {
    return {
      loader: false
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    storeBariResource(event) {
      let vm = this
      vm.loader = true
      var formData = new FormData()
      _.each(event, (value, key) => {
        formData.append(key, value)
      })
      const config = {headers: {'content-type': 'multipart/form-data'}}
      vm.$store.dispatch('BariResourceModule/_storeBariResource', {config, formData})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Bari Resource Created Successfully!'
          })
          vm.$router.back()
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Bari Resource',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    }
  }
}
</script>

<style scoped>

</style>
